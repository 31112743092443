import React, { useState } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Form as FormikForm, Formik } from 'formik'
import StaticSiteMessageRow from './feedback/StaticSiteMessageRow'
import { Select, TextField } from './form'
import { required } from './form/validators';
import styled from 'styled-components/macro';
import { device } from '../utils/breakpoints';
import FinanceIcon from '../images/Finance.svg'
import HealthcareIcon from '../images/Healthcare.svg'
import TelecomIcon from '../images/Telecom.svg'
import client from '../utils/httpClient'
import parseApiErrorMessage from '../utils/parseApiErrorMessage'

const TrustedSection = styled.div`

    @media ${device.laptop} {
        font-size: 40px;
    }
`;

const Heading1 = styled.h1`
  font-size: 50px;
  font-weight: 650;
`

const Heading4 = styled.h4`
  font-size: 24px;
`

const TrustBorder = styled.div`
    border-bottom: 8px solid #052475;
    width: 76px;
    margin: 25px 0;
`;

const BoxItem = styled.div`
    background-color: #F9FAFD;
    width: 70%;
    height: 100px;
    border-radius: 10px;
    margin: 25px 0;
    justify-content: center;
    display: flex;
    align-items: center;

    @media ${device.laptop} {
        width: 100%;
      }
    ;
`;

const ContactSection = styled.div`
    padding-bottom: 35px;
`;

const ContactBorder = styled.div`
    border-bottom: 1px solid rgb(230, 230, 230) ;
    margin: 30px 0;
`;

const ButtonContainer = styled.div`
    text-align: right;
    margin: 1.5rem 0;
    button {
       background-color: #052475;
       border: 0;
       padding: 10px 40px;
       color: white;
       border-radius: 10px;
    }
`;

const Logo = styled.div`
    display: flex;
    align-items: center;

    image{
        width: 100%;
    }
    svg {
        width: 50px;
        height: 50px;
    }
    h4 {
        margin: 0 10px;
    }
    .w-70 {
        width: 70%;
        
    }
    .w-30 {
        width: 30%;
        text-align: end;
    }
    .w-100{
        width: 100%;
    }
`

const purposeOption = [
    { label: "Request a Demo", value: "Request a Demo" },
    { label: "Premium Pricing", value: "Premium Pricing" }
]

export default function ContactPage() {
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState(null)

    return (
        <Container className='py-5'>
            <Row>
                <Col lg={6}>

                    <TrustedSection>
                        <Heading1>Trusted By</Heading1>
                        <TrustBorder></TrustBorder>
                        <BoxItem >
                            <Logo className='w-100'>
                                <div className='w-30'>
                                    <HealthcareIcon />
                                </div>
                                <div className='w-70'>
                                    <h4>Healthcare</h4>
                                </div>
                            </Logo>
                        </BoxItem>
                        <BoxItem>
                            <Logo className='w-100'>
                                <div className='w-30'>
                                    <TelecomIcon />
                                </div>
                                <div className='w-70'>
                                    <h4>Telecoms</h4>
                                </div>
                            </Logo>
                        </BoxItem>
                        <BoxItem>
                            <Logo className='w-100'>
                                <div className='w-30'>
                                    <FinanceIcon />
                                </div>
                                <div className='w-70'>
                                    <h4>Finance and Fintech</h4>
                                </div>
                            </Logo>
                        </BoxItem>
                    </TrustedSection>
                </Col>
                <Col lg={6} id="contact">
                    <ContactSection>
                        <Heading4>Want to know more about Ametnes? Leave your contact details and we will reach out to you</Heading4>
                        <ContactBorder />
                        <StaticSiteMessageRow variant='danger'>{error}</StaticSiteMessageRow>
                        <StaticSiteMessageRow variant='success'>{successMessage}</StaticSiteMessageRow>
                        <ContactForm onError={setError} onSuccess={setSuccessMessage} />
                    </ContactSection>
                </Col>
            </Row>
        </Container>
    )
}

function ContactForm({
    onError,
    onSuccess
}) {

    function handleSubmit(values, actions) {
        client.put('contact', {
            from: values.email,
            subject: values.purpose,
            text: `Hello, I am ${values.fullName} (${values.company || ''})`
        })
            .then(() => {
                actions.setSubmitting(false);
                actions.resetForm();
                onSuccess("We received your email! will get back to you shortly!!!");
            })
            .catch((error) => {
                actions.isSubmitting(false);
                onError(parseApiErrorMessage(error));
            })
    }

    return <>
        <Formik initialValues={{
            purpose: '',
            fullName: '',
            email: '',
            company: ''
        }}
            onSubmit={handleSubmit}>
            {({ isSubmitting, resetForm }) => (
                <FormikForm>
                    <Select
                        label="Purpose"
                        name="purpose"
                        placeholder="Select purpose"
                        options={purposeOption}
                    />
                    <TextField
                        label="Full Name"
                        id="fullName"
                        placeholder="name"
                        name="fullName"
                        validate={required}
                    />
                    <TextField
                        label="Work Email"
                        id="email"
                        placeholder="email"
                        name="email"
                        validate={required}
                    />
                    <TextField
                        label="Company"
                        id="company"
                        placeholder="company"
                        name="company"
                    />

                    <ButtonContainer>
                        <button
                            disabled={isSubmitting}
                            type="submit"
                        >
                            Submit
                        </button>
                    </ButtonContainer>
                </FormikForm>
            )}
        </Formik>
    </>
}
